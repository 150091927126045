import React from "react";

import { GatsbyImage } from "gatsby-plugin-image";

import { graphql, Link } from "gatsby";

import Layout from "../../components/Layout";

import { Helmet } from "react-helmet";

const AufbissschienenPage = ({ data }) => {
  const { html } = data.markdownRemark;
  const pageFrontmatter = data.markdownRemark.frontmatter;

  return (
    <React.Fragment>
      <Helmet>
        <html lang="de" />
        <title>Aufbisschienen - Zahnarztpraxis Dr. Sandra Faltermeier</title>
        <meta
          name="description"
          content="Emotionaler Stress wird bei vielen Menschen in Form von Zähneknirschen abgebaut. Dies geschieht meist nachts und führt langfistig zu abgeschliffenen Zähnen. Die teils enormen Kräfte belasten sowohl die Kaumuskulatur als auch die Hals-Nacken-und Rückenmuskulatur."
        />

        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta
          property="og:title"
          content="Aufbisschienen - Zahnarztpraxis Dr. Sandra Faltermeier"
        />
        <meta
          property="og:description"
          content="Emotionaler Stress wird bei vielen Menschen in Form von Zähneknirschen abgebaut. Dies geschieht meist nachts und führt langfistig zu abgeschliffenen Zähnen. Die teils enormen Kräfte belasten sowohl die Kaumuskulatur als auch die Hals-Nacken-und Rückenmuskulatur."
        />
        <meta property="og:url" content="/leistungen/aufbissschienen" />
      </Helmet>
      <Layout>
        <div class="sm:mb-80p sm:px-25p">
          {pageFrontmatter.imageObject && (
            <GatsbyImage
              class="mb-40p"
              loading="eager"
              image={
                pageFrontmatter.imageObject.image.childImageSharp
                  .gatsbyImageData
              }
              alt={pageFrontmatter.imageObject.alt}
              title={pageFrontmatter.imageObject.title}
            />
          )}
        </div>
        <div class="max-w-1000p mx-auto px-5 mini:px-25p">
          <div
            data-sal={`${!pageFrontmatter.imageObject ? "slide-up" : null}`}
            data-sal-duration={`${!pageFrontmatter.imageObject ? "700" : null}`}
            data-sal-delay={`${!pageFrontmatter.imageObject ? "500" : null}`}
            data-sal-easing={`${!pageFrontmatter.imageObject ? "ease" : null}`}
            class="markdownLeistungen mb-40p sm:mb-80p"
            dangerouslySetInnerHTML={{ __html: html }}
          />

          <div class="flex flex-col space-y-5 sm:space-y-0 sm:flex-row justify-evenly items-center max-w-5xl mx-auto sm:space-x-5">
            <Link
              data-sal="zoom-in"
              data-sal-duration="500"
              data-sal-easing="ease"
              class="text-secondary font-medium text-20p sm:text-25p text-center"
              to="/leistungen"
            >
              Zurück zur Leistungsübersicht
            </Link>
            <Link
              data-sal="zoom-in"
              data-sal-duration="500"
              data-sal-easing="ease"
              class="text-20p sm:text-25p text-white bg-cta hover:bg-cta2 p-20p sm:px-25p sm:py-3 text-center"
              to="https://www.doctolib.de/einzelpraxis/regensburg/zahnarztpraxis-am-candis-fr-dr-sandra-faltermeier"
            >
              Jetzt Termin vereinbaren
            </Link>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  );
};

export default AufbissschienenPage;

export const query = graphql`
  {
    markdownRemark(
      frontmatter: { templateKey: { eq: "aufbissschienen-page" } }
    ) {
      frontmatter {
        title
        subtitle
        imageObject {
          title
          alt
          image {
            childImageSharp {
              gatsbyImageData(quality: 80, formats: [WEBP, AUTO])
            }
          }
        }
      }
      html
    }
  }
`;
